import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import { Link } from "gatsby"
import Particle11 from "../components/Particle11"
import SEO from "../components/SEO"
import "../css/dsa.css"

const dsa = () => {
    return (
        <div>
            <Layout>
                <SEO
                    title="DSA (Basics)"
                    description="Projects of Data Structures and Algorithm (Bascis) with Python."
                />
                <Particle11></Particle11>
                <h6>s</h6>
                <div className="programming-project">
                    <h1>Machine Learning</h1>
                </div>



                <section className="dsa-d noSelect">
                    <Link to="/ml_project/">
                        <div type="button" className="dsa">
                            <span>ML Projects</span>
                        </div>
                    </Link>
                    <br />
                    <Link to="/ml_model/">
                        <div type="button" className="dsa">
                            <span>ML Models</span>
                        </div>
                    </Link>

                </section>
            </Layout>
        </div>
    )
}

export default dsa
